import React from 'react';
import styled, { css } from 'styled-components';

const StyledGrid = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-gap: ${theme.global.edgeSize.medium};
        padding-bottom: ${theme.global.edgeSize.large};
        justify-content: center;

        @media (min-width: 992px) {
        grid-gap: ${theme.global.edgeSize.large};

        }
};
    `}
`;

const ResponsiveGrid = ({ children }) => <StyledGrid>{children}</StyledGrid>;

export default ResponsiveGrid;
