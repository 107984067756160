import React from 'react';
import { graphql } from 'gatsby';
import Entry from './entry';

const PageEntry = ({ item }) => {
    if (!item) {
        return null;
    }

    const { featuredImage, title, uri } = item;
    return <Entry featuredImage={featuredImage} title={title} uri={uri} type="pages" />;
};

export default PageEntry;

export const query = graphql`fragment PageEntryFragment on WpPage {
  id
  title
  uri
  slug
  featuredImage {
    node {
      sourceUrl
      mediaItemUrl
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(width: 300, height: 300, quality: 80, layout: FIXED)
        }
      }
    }
  }
}
`;
