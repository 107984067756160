import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import Intro from './intro';
import ResponsiveGrid from './responsiveGrid';

import PageEntry from './pageEntry';

interface subPagesProps {
    childNodes: any[];
    heading?: string;
    intro?: string;
    nodeID?: string;
    nodeType: string;
}

const subPages: FC<subPagesProps> = ({ heading, intro, childNodes, nodeType }) => (
    <Box className="light-1" pad={{ vertical: 'xlarge' }} gap="large" direction="column">
        <Intro heading={heading} intro={intro} />
        <ResponsiveGrid gap="medium">
            {childNodes && childNodes.length >= 1 && childNodes.map(item => <PageEntry item={item} />)}
        </ResponsiveGrid>
    </Box>
);

export default subPages;
