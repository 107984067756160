import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

const Soon = ({ size = '300px 300px' }) => {
    const data = useStaticQuery(graphql`
        {
            placeholderImage: file(relativePath: { eq: "coming-soon.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 300, height: 280, quality: 100, layout: FIXED)
                }
            }
        }
    `);

    return <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} alt="" />;
};

export default Soon;
